import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import AddLocation from "@material-ui/icons/AddLocation";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// import { Apps } from "@material-ui/icons";
const useStyles = makeStyles(styles);

import TrailerContents from "views/Components/Sections/TrailerContents";

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Trailer Rental</h2>
          <h5 className={classes.description}>
            Our trailer rental provides the essential items for grip work.
            Lights, stands, and bounce screens.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <TrailerContents />
            </ListItem>
          </List>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Delivery and Pickup Included"
              description="Delivery and pickup are included if using our bundled videographer."
              icon={AddLocation}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
