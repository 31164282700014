/*eslint-disable*/
import * as React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Images
// import Image from "@mui/material/Image";
import skyHigh from "assets/img/equipment-pics/sky-high.png";
import beefyBaby from "assets/img/equipment-pics/beefy-baby.png";
import SolidRag6x6 from "assets/img/equipment-pics/6x6-solid-rag.png";
import silk12x12 from "assets/img/equipment-pics/12x12-silk.jpg";
import by4WhiteSilver from "assets/img/equipment-pics/4x4-white-silver.png";
import by4SingleNet from "assets/img/equipment-pics/4x4.jpg";
import diffusion4x4 from "assets/img/equipment-pics/4x4-diffusion.jpg";
import floppy4x4 from "assets/img/equipment-pics/4x4-floppy.jpg";
import frame6x6 from "assets/img/equipment-pics/6x6-frame.jpg";
import largeClamp from "assets/img/equipment-pics/large-clamp.jpg";
import frame12x12 from "assets/img/equipment-pics/12x12-frame.jpg";
import danaDolly from "assets/img/equipment-pics/dana-dolly.jpg";
import sandBag from "assets/img/equipment-pics/sand-bag.jpg";
import roadFlagkit from "assets/img/equipment-pics/2x4-road-flag-kit.jpg";

import alumSpeedRailTube6ft from "assets/img/equipment-pics/Grip/Grip/6ft Aluminum Speed Rail_Scaff Tube Ø 1-1_4”.jpg";
import safetyCable235 from "assets/img/equipment-pics/Grip/Grip/23.5_ Safety Cable Wire with Carabiner.jpg";
import assortedJawViseGrips from "assets/img/equipment-pics/Grip/Grip/Assorted Jaw Vise Grip.jpg";
import avengerD650JuniorBoomArmWithCounterweight from "assets/img/equipment-pics/Grip/Grip/Avenger D650 Junior Boom Arm with Counterweight.jpg";
import chauvetDjHurricaneHaze1DXWaterBasedHazeMachine from "assets/img/equipment-pics/Grip/Grip/CHAUVET DJ Hurricane Haze 1DX Water-Based Haze Machine.jpg";
import ceilingScissorClampMounts from "assets/img/equipment-pics/Grip/Grip/Ceiling Scissor Clamp Mounts Up to Medium Size Light Fixtures to Drop Ceiling Frame, 5_8_ Mounting Stud.jpg";
import kupoScraperWithBaby58Stud from "assets/img/equipment-pics/Grip/Grip/Kupo Scraper with Baby 5_8_ Stud.jpg";
import magicCloth from "assets/img/equipment-pics/Grip/Grip/Magic Cloth (6 x 6_).jpg";
import matthewsButterflyOverheadFabricLiteSilentGridcloth from "assets/img/equipment-pics/Grip/Grip/Matthews Butterfly_Overhead Fabric - 6x6_ - Lite Silent Gridcloth.jpg";
import matthewsCClampWith2BabyPins from "assets/img/equipment-pics/Grip/Grip/Matthews C - Clamp with 2- 5_8_ Baby Pins - 6_.jpg";
import matthewsHollywoodGripHead from "assets/img/equipment-pics/Grip/Grip/Matthews Hollywood Grip Head (4-1_2_).jpg";
import matthewsMenaceArmKit from "assets/img/equipment-pics/Grip/Grip/Matthews Menace Arm Kit for 1 1_4_ with pipe connector.jpg";
import matthewsMomboComboStand from "assets/img/equipment-pics/Grip/Grip/Matthews Mombo Combo Stand with Extra Wide Base, Supports 88 lbs, Maximum Height 288_, (24_).jpg";
import matthewsUnbleachedMuslinOverheadFabric from "assets/img/equipment-pics/Grip/Grip/Matthews Unbleached Muslin Overhead Fabric (5.9 x 5.9_).jpg";
import photoflexExtraLongLitereachPlus from "assets/img/equipment-pics/Grip/Grip/Photoflex Extra-Long Litereach Plus.jpg";
import recessedLightSocketTo58inBabyPinAdapter from "assets/img/equipment-pics/Grip/Grip/Recessed Light Socket to 5_8in Baby Pin Adapter.jpg";
import setOfFourAppleBoxes from "assets/img/equipment-pics/Grip/Grip/Set of Four Apple Boxes, Full, Half, Quarter & Pancake.jpg";
import silentHalfGridCloth from "assets/img/equipment-pics/Grip/Grip/Silent 1_2 Grid Cloth.jpg";
import singleNetScrim from "assets/img/equipment-pics/Grip/Grip/Single Net Scrim (48 x 48_).jpg";
import smokegenieSmokeNinjaHandheldBatteryPoweredWirelessMiniSmokeMachine from "assets/img/equipment-pics/Grip/Grip/SmokeGENIE Smoke Ninja Handheld Battery-Powered Wireless Mini Smoke Machine.jpg";
import tiltaHydraAlienCarMountingSystem from "assets/img/equipment-pics/Grip/Grip/Tilta Hydra Alien Car Mounting System (V-Mount).jpg";
import ultrabounceFloppyWithPocket from "assets/img/equipment-pics/Grip/Grip/Ultrabounce Floppy with Pocket (48 x 48_, Black_White).jpg";
import soundAcousticMovingBlanketFurniePad from "assets/img/equipment-pics/Grip/Grip/_Sound Acoustic_ moving blanket_furnie pad.jpg";

//import fullSizeCStands from "assets/img/equipment-pics/";
//import miniCStands from "assets/img/equipment-pics/";
//import fullSizeComboStands from "assets/img/equipment-pics/";
//import smallComboStands from "assets/img/equipment-pics/";
import imageUnavailable from "assets/img/equipment-pics/image-unavailable.png";

// core components

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Collapse from "@mui/material/Collapse";
import { ListItemText } from "@material-ui/core";
import { Grid, ListItemButton } from "@mui/material";

const useStyles = makeStyles(styles);
const textStyle = { color: "#3C4858" };
const imgHeight = "100px";
const IMAGES = {
  "Sky High": skyHigh,
  "Beefy Baby": beefyBaby,
  "6x6 Solid Rag": SolidRag6x6,
  "12x12 Silk": silk12x12,
  "4 By With White/Silver": by4WhiteSilver,
  "4x4 250/Hemp Diffusion": diffusion4x4,
  "4x4 Hemp Diffusion": "",
  "4x4 Single Net and Double Net": by4SingleNet,
  "2 4x4 Floppies": floppy4x4,
  "6x6 Frame with White, Silver, or Silk Option": frame6x6,
  "2x4 Road Flag Kit": roadFlagkit,
  "2 Large Clamps": largeClamp,
  "Assorted Grip Clips/Clamps": "",
  "12x12 Frame": frame12x12,
  "Dana Dolly with 5' and 10' Track": danaDolly,
  "7 Sand Bags": sandBag,
  "5 Full Size C Stands": imageUnavailable,
  "3 Mini C Stands": imageUnavailable,
  "3 Full Size Combo Stands": imageUnavailable,
  "2 Small Combo Stands": imageUnavailable,
  "1 1/4\" Aluminum Speed Rail Tube": alumSpeedRailTube6ft,
  "23.5\" Safety Cable Wire with Carabiner": safetyCable235,
  "Assorted Jaw Vise Grips": assortedJawViseGrips,
  "Avenger D650 Junior Boom Arm with Counterweight": avengerD650JuniorBoomArmWithCounterweight,
  "Chauvet DJ Hurricane Haze Machine": chauvetDjHurricaneHaze1DXWaterBasedHazeMachine,
  "Ceiling Scissor Clamp Mounts": ceilingScissorClampMounts,
  "Kupo Scraper with Baby Stud": kupoScraperWithBaby58Stud,
  "Magic Cloth": magicCloth,
  "Matthews Butterfly Overhead Fabric Lite Silent Grid Cloth": matthewsButterflyOverheadFabricLiteSilentGridcloth,
  "Matthews C Clamp with 2 Baby Pins": matthewsCClampWith2BabyPins,
  "Matthews Hollywood Grip Head": matthewsHollywoodGripHead,
  "Matthews Menace Arm Kit": matthewsMenaceArmKit,
  "Matthews Mombo Combo Stand": matthewsMomboComboStand,
  "Matthews Unbleached Muslin Overhead Fabric": matthewsUnbleachedMuslinOverheadFabric,
  "Photo Flex Extra Long Lite Reach Plus": photoflexExtraLongLitereachPlus,
  "Recessed Light Socket To 5/8\" Baby Pin Adapter": recessedLightSocketTo58inBabyPinAdapter,
  "Set of 4 Apple Boxes": setOfFourAppleBoxes,
  "Silent Half Grid Cloth": silentHalfGridCloth,
  "Single Net Scrim": singleNetScrim,
  "Smoke Ninja Wireless Mini Smoke Machine ": smokegenieSmokeNinjaHandheldBatteryPoweredWirelessMiniSmokeMachine,
  "Hydra Alien Car Mounting System": tiltaHydraAlienCarMountingSystem,
  "Ultra Bounce Floppy with Pocket": ultrabounceFloppyWithPocket,
  "Sound Accoustic Moving Blanket Furnie Pad": soundAcousticMovingBlanketFurniePad,
};

export default function TrailerContents(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleEquipmentClick = () => {
    setOpen(!open);
  };
  const EQUIPMENTLIST = ({ items }) =>
    Object.entries(items).map(([k, v]) => (
      <Grid item xs={6} sm={6} md={4} lg={2} textAlign="center">
        <ListItemText
          primary={k}
          style={textStyle}
          className={classes.description}
        />
        <img src={v} height={imgHeight} />
      </Grid>
    ));
  return (
    <List>
      <ListItemButton onClick={handleEquipmentClick}>
        <ListItemText
          primary="Equipment"
          style={{ ...textStyle, borderBottom: 1, borderColor: "primary" }}
          className={classes.description}
        />
        {open ? (
          <ExpandLess style={textStyle} />
        ) : (
          <ExpandMore style={textStyle} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <EQUIPMENTLIST items={IMAGES} />
        </Grid>
      </Collapse>
    </List>
  );
}
